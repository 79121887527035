import {updateHeaderCartIcon} from "./cart-icon";
/// Selects customer dropdown
const $currentCustomer = $('#current-customer');
const expiryDays = 56;

/// Change handler on Customer drop down, sets cookie with customer number
$currentCustomer.change(function (e) {
    setCookie('customer_number', e.target.value, expiryDays);
    window.location.reload();
});


/// Updates shopping cart button with quantity
renderShoppingCartButton();
updateHeaderCartIcon();

/// Click handler Add to cart button
$('.add-to-cart').click(function (e) {
    e.preventDefault();

    const $this = $(this);

    const $row = $this.parents('.np-shop-item');
    const productId = parseInt($row.find('input.product-id').val()) || 0;
    const amount = parseFloat($row.find('.np-shop-qty').val().replace(',', '.')) || 0;

    if (productId && amount) {
        console.log(productId, amount);
        addItem(productId, amount);
        renderShoppingCartButton();
        updateHeaderCartIcon();
    }

    // var $shoppingCart = $('#shopping-cart');
    // $shoppingCart.css('transition', 'background 100ms').css('background', '#F20A37');
    // setTimeout(function () {
    //     $shoppingCart.css('transition', 'background 1000ms');
    //     $shoppingCart[0].style.background = '';
    //     setTimeout(function () {
    //         $shoppingCart[0].style.transition = '';
    //     }, 500);
    // }, 200);
    //
    // var $i = $this.find('i');
    // $i.removeClass('fa-cart-plus').addClass('fa-circle-notch fa-spin');
    // setTimeout(function () {
    //     $i.removeClass('fa-circle-notch fa-spin').addClass('fa-check');
    //     setTimeout(function () {
    //         $i.removeClass('fa-check').addClass('fa-cart-plus');
    //     }, 2000);
    // }, 500);
});

/// Removes items from shoping cart
$('.shopping-cart .remove-item').click(function (e) {
    e.preventDefault();
    const $row = $(this).parents('.row');
    const index = $row.data('index');

    removeItem(index);

    $row.fadeOut(function () {
        $row.remove();
        updateCartTotal();
        renderShoppingCartButton();
        updateHeaderCartIcon();

        if (getCartTotal() <= 0) {
            window.location.reload();
        }
    });
});

$('.shopping-cart .subtract').click(function (e) {
    e.preventDefault();
    const $row = $(this).parents('.row');
    updateCartItem($row, 'subtract');
});

$('.shopping-cart .send-order').click(function (e) {
    e.preventDefault();
    const $form = $(this).parents('form');
    $form.submit();
})

$('.shopping-cart .add').click(function (e) {
    e.preventDefault();

    const $row = $(this).parents('.row');
    updateCartItem($row, 'add');
});

$('.shopping-cart .count input').change(function () {
    const $row = $(this).parents('.row');
    updateCartItem($row, 'update');
});

// method: 'subtract', 'add', 'update'
function updateCartItem($row, method) {
    const index = $row.data('index');

    const items = getCartItems();
    let currentCount = items[index].count || 0;
    let newCount = 0;

    switch (method) {
        case 'add':
            newCount = currentCount + 1;
            break;
        case 'subtract':
            newCount = currentCount - 1;
            break;
        case 'update':
            newCount = parseFloat($row.find('.count input').val().replace(',', '.')) || 0;
            break;
    }

    const price = parseFloat($row.find('.price input').val()) || 0;

    newCount = Math.max(newCount, 0);
    let item = items[index];
    item.count = newCount;
    items[index] = item;

    $row.find('.count input').val(newCount.toLocaleString('no'));
    $row.find('.total').html(priceFormat(price * newCount));

    saveCartItems(items);
    updateCartTotal();
    renderShoppingCartButton();
    updateHeaderCartIcon();
}

function updateCartTotal() {
    const total = getCartTotal();
    $('.shopping-cart .foot .price.total').html(priceFormat(total));
}

function getCartTotal() {
    var total = 0;

    $('.shopping-cart .table .row:not(.foot)').each(function (i, tr) {
        var $row = $(tr);
        var count = parseFloat($row.find('.count input').val().replace(',', '.')) || 0;
        var price = parseFloat($row.find('.price input').val()) || 0;

        total += count * price;
    });

    return total;
}

// Updates the amount in shopping cart button
function renderShoppingCartButton() {
    const $shoppingCart = $('#shopping-cart');

    if ($shoppingCart.length === 0) {
        return;
    }

    const $span = $shoppingCart.find('span');

    const items = getCartItems();

    $span.html('' + items.length + (items.length === 1 ? ' vare' : ' varer'));
}

// Returns Cart Items
function priceFormat(price) {
    return price.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$& ') + ',-';
}

function getCartItems() {
    try {
        const cartItems = JSON.parse(getCookie('shopping_cart')) || [];

        // Convert old cart items to new format
        if (!Array.isArray(cartItems)) {
            var items = [];
            var keys = Object.keys(cartItems);
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                var newObject = {
                    productId: key,
                    count: cartItems[keys[i]]
                }
                items.push(newObject);
            }
            return items;
        }

        return cartItems;
    } catch (e) {
        return {};
    }
}

// Adds item to cart array. Calls Save Cart.
function addItem(id, addCount) {
    let items = getCartItems();

    items.push({
        productId: id,
        count: addCount
    });

    saveCartItems(items);
}

// Removes item from cart by index. Calls Save Cart.
function removeItem(index) {
    let items = getCartItems();

    items = items.slice(0, index).concat(items.slice(index + 1));

    saveCartItems(items);
}

// UTILITY FUNCTIONS:

function saveCartItems(items) {
    setCookie('shopping_cart', JSON.stringify(items), expiryDays);
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}


function getCookie(name) {
    const nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}
