const referencesSider = document.querySelector('.block-references__slider');
if (referencesSider) {
    const sliderCases = document.querySelectorAll('.block-references__slider-case');
    const nextBtn = document.querySelector('#ref-next-btn');
    const prevBtn = document.querySelector('#ref-prev-btn');
    const pageBtns = document.querySelectorAll('.block-references__slider-page-button');
    let slideIndex = 0;

    function updatePageIndicator() {
        pageBtns.forEach((pageBtn, index) => {
            pageBtn.classList.remove('active');
            if (index === slideIndex) pageBtn.classList.add('active');
        });
    }
    updatePageIndicator();

    nextBtn.addEventListener('click', () => {
        if (slideIndex >= sliderCases.length - 1) return;
        slideIndex++;
        sliderCases.forEach((caseItem) => {
            caseItem.style.translate = `${-100 * slideIndex}%`;
        });
        updatePageIndicator();
    });
    prevBtn.addEventListener('click', () => {
        if (slideIndex == 0) return;
        slideIndex--;
        sliderCases.forEach((caseItem) => {
            caseItem.style.translate = `${-100 * slideIndex}%`;
        });
        updatePageIndicator();
    });

    pageBtns.forEach((pageBtn, index) => {
        pageBtn.addEventListener('click', () => {
            slideIndex = index;
            sliderCases.forEach((caseItem) => {
                caseItem.style.translate = `${-100 * slideIndex}%`;
            });
            updatePageIndicator();
        });
    });
}
