import Glide from "@glidejs/glide";
import { intersectionObserve } from "./_intersection-observer";

function initLogoSlider(id) {
    var logoGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        breakpoints: {
            1000: {
                perView: 2
            },
            600: {
                perView: 1
            }
        },
        gap: 0,
        autoplay: 3500,
        animationDuration: 1000,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    var logos = document.getElementById(id);
    if (logos && logos.querySelector('.glide__slides').children.length > 1) {
        logoGlide.mount();

        var count = parseInt(logos.getAttribute('data-count')) || 0;

        if (!isSliderVisible(logoGlide) || (window.innerWidth < 1000 && count <= 1) || (window.innerWidth >= 1000 && count <= 2)) {
            logoGlide.update({autoplay:false});
            logoGlide.pause()

        }
        if(isSliderVisible(logoGlide) || (window.innerWidth < 1000 && count <= 1) || (window.innerWidth >= 1000 && count <= 2)){
            logoGlide.play();
        }

        var parent = logos.parentNode;
        if (parent) {
            var prevButton = parent.querySelector('.glide-nav-button.glide-prev');
            if (prevButton) {
                prevButton.addEventListener('click', function (e) {
                    e.preventDefault();
                    logoGlide.go('<');
                });
            }

            var nextButton = parent.querySelector('.glide-nav-button.glide-next');
            if (nextButton) {
                nextButton.addEventListener('click', function (e) {
                    e.preventDefault();
                    logoGlide.go('>');
                });
            }
        }
    }

    intersectionObserve(logos, 0, function(element, intersectionRatio) {
        if (intersectionRatio > 0 && (window.innerWidth < 1000 && count > 1) || (window.innerWidth >= 1000 && count > 2)) {
            logoGlide.play();
            logoGlide.update({autoplay:'3500'});
        }
        if(intersectionRatio <= 0){
            logoGlide.update({autoplay:false});
        }
    });
}

function isSliderVisible(element) {
    return window.scrollY + window.innerHeight >= element.offsetTop + element.offsetHeight / 2;
}

window.initLogoSlider = initLogoSlider;