const hamburger = document.getElementById('hamburger')
const nav = document.getElementById('main-nav')
const page = document.querySelector('main.content')
let menuOpen = false;

// If hamburger exists add event listeners
if ( hamburger != null && nav != null ) {
    // Toggle mobile menu
    hamburger.addEventListener( 'click', () => {
            nav.classList.toggle('open')
            menuOpen = !menuOpen;
        }
    )

    //  Escape closes mobile menu
    document.addEventListener('keydown', (e) => {
        if (menuOpen && e.key === "Escape") {
            nav.classList.remove('open')
            menuOpen = false
        }
    })
}

//Click away event listener
if (page != null) {
    page.addEventListener('click', () => {
        if (menuOpen === true) {
            nav.classList.remove('open')
            menuOpen = false
            console.log('never')
        }
    })
}