$('.my-page-table .order .toggle-order').click(function (e) {
    e.preventDefault();

    var $this = $(this);
    var $order = $this.parents('.order');

    var $chevron = $this.find('.chevron i');
    if ($chevron.hasClass('open')) {
        $chevron[0].style.transform = 'none';
        $chevron.removeClass('open');
        $order.removeClass('open');
    } else {
        $chevron[0].style.transform = 'rotate(180deg)';
        $chevron.addClass('open');
        $order.addClass('open');
    }

    var $lines = $order.find('.lines');
    $lines.toggle();
});

var $lines = $('.my-page-table .lines .row:not(.row-header)');
$lines.find('.subtract').click(function(e) {
    var $amountInput = $(this).siblings('.amount').find('input');
    var amount = parseInt($amountInput.val()) || 0;

    updateAmount($amountInput, amount - 1)
});

$lines.find('.add').click(function(e) {
    var $amountInput = $(this).siblings('.amount').find('input');
    var amount = parseInt($amountInput.val()) || 0;

    updateAmount($amountInput, amount + 1)
});

function updateAmount($input, amount) {
    amount = amount || 0;
    amount = Math.max(amount, 1);

    $input.val('' + amount);
}

var $tabs = $('.my-page-tabs');
var $orderHistory = $('.order-history');
var $spareParts = $('.spare-parts');
var $documentation = $('.documentation');
$tabs.find('a').click(function(e) {
    e.preventDefault();

    var $this = $(this);

    var $link = $tabs.find('a');
    $link.removeClass('active');
    $this.addClass('active');

    $orderHistory.hide();
    $spareParts.hide();
    $documentation.hide();

    var tab = $this.data('tab') || 'order-history';

    $('.' + tab).show();

    window.location.hash = $this[0].href.split('#')[1];
});

if (window.location.hash && window.location.hash !== '#') {
    $tabs.find('a').removeClass('active');

    switch (window.location.hash) {
        case '#ordrehistorikk':
            $orderHistory.show();
            $spareParts.hide();
            $documentation.hide();
            $tabs.find('a[data-tab="order-history"]').addClass('active');
            break;
        case '#reservedeler':
            $orderHistory.hide();
            $spareParts.show();
            $documentation.hide();
            $tabs.find('a[data-tab="spare-parts"]').addClass('active');
            break;
        case '#dokumentasjon':
            $orderHistory.hide();
            $spareParts.hide();
            $documentation.show();
            $tabs.find('a[data-tab="documentation"]').addClass('active');
            break;
    }
}