
function getShoppingCart() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.startsWith('shopping_cart=')) {
            return JSON.parse(cookie.substring(14));
        }
    }
    return null;
}

function calculateQuantity() {
    const shoppingCart = getShoppingCart();
    let quantity = 0;
    if (shoppingCart) {
        for (let i = 0; i < shoppingCart.length; i++) {
            quantity += shoppingCart[i].count;
        }
    }
return quantity
}

export function updateHeaderCartIcon() {
    const cartIcon = document.getElementById('header-cart-icon');
    const quantity = calculateQuantity();
    if (cartIcon) {
        if (quantity === 0) {
            cartIcon.classList.remove('cart-has-items')
        }
        if (quantity > 0) {
            cartIcon.classList.add('cart-has-items')
            cartIcon.dataset.content = `${quantity}`;
        }
    }

}
