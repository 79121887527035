var mobileBreakPoint = 1000;

function setupHotspots() {
    var $hotspotsImages = $('.hotspots-image');
    for (var i = 0; i < $hotspotsImages.length; i++) {

        (function ($hotspotsImage) {

            $hotspotsImage.find('.hotspots:not(.no-hover)').find('.hotspot').off('mouseenter').mouseenter(showHotspot).off('mouseleave').mouseleave(hideHotspot).click(function(e) {
                e.preventDefault();
                e.stopPropagation();
            });

        })($($hotspotsImages[i]));

    }

    $(document).on('touchend', function() {
        if (!$hotspotsImages.hasClass('nordic-bulk-transporter')) {
            hideHotspots();
        }
    });
}

export function showHotspot(e, $hotspot, isTransporter) {
    $hotspot = $hotspot || $(this);
    var $container = $hotspot.parents('.hotspots').parent();

    var $infoBox = $container.find('.infobox');
    $infoBox.html($hotspot.find('.info').html());
    $infoBox.show();

    $infoBox.find('a').prepend('<i class="fas fa-arrow-right"></i>');

    var $line = $container.find('.line');

    var x = parseFloat($hotspot.data('x')) || 0;
    var y = parseFloat($hotspot.data('y')) || 0;

    if (window.innerWidth < mobileBreakPoint) {
        $infoBox.css({bottom: '', top: '98%', left: '50%', transform: 'translateX(-50%)'});
    } else {
        if (isTransporter) {
            var $prevElement = $container.parents('.nordic-bulk-transporter').prev();

            if (x <= 50) {
                $infoBox.css({bottom: '70%', top: '', left: '', right: '10%'});

                if ($infoBox.offset().top < $prevElement.offset().top + $prevElement.outerHeight()) {
                    var top = $container.offset().top - ($prevElement.offset().top + $prevElement.outerHeight() + 20);
                    $infoBox.css({bottom: '', top: '-' + top + 'px'});
                }
            } else {
                $infoBox.css({bottom: '', top: '60%', left: '10%', right: ''});

                if ($infoBox.outerHeight() > 300) {
                    $infoBox.css('top', '40%');
                }
            }
        } else {
            if (y <= 50) {
                $infoBox.css({bottom: '', top: '-4em', left: '50%', transform: 'translateX(-50%)'});
            } else {
                $infoBox.css({bottom: '-4em', top: '', left: '50%', transform: 'translateX(-50%)'});
            }

            var padding = 30;
            if ($hotspot.position().left + $hotspot.outerWidth() > $infoBox.position().left - padding && $hotspot.position().left <= $infoBox.position().left + $infoBox.outerWidth() + padding) {
                if (y <= 50 && $hotspot.position().top < $infoBox.position().top + $infoBox.outerHeight()) {
                    $infoBox.css({bottom: '-3em', top: ''});
                } else if ($hotspot.position().top + $hotspot.outerHeight() >= $infoBox.position().top - padding) {
                    $infoBox.css({bottom: '', top: '-3em'});
                }
            }
        }
    }

    var x1 = $hotspot.position().left + $hotspot.width() / 2;
    var y1 = $hotspot.position().top + $hotspot.height() / 2;
    var x2 = $infoBox.position().left + $infoBox.width() / 2;
    var y2 = $infoBox.position().top + $infoBox.height() / 2;

    var length = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    var angle = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    var transform = 'rotate(' + angle + 'deg)';

    $line.show();
    $line.css({top: y1, left: x1, transform: transform, width: length});
    //$line.css({top: y1, left: x1, width: length});
}

function hideHotspot() {
    var $hotspot = $(this);
    var $container = $hotspot.parents('.hotspots').parent();

    var $infoBox = $container.find('.infobox');
    var $line = $container.find('.line');

    $infoBox.hide();
    $line.hide();
}

function hideHotspots() {
    $('.infobox').hide();
    $('.line').hide();
}

$(document).ready(function () {
    setupHotspots();
});

window.setupHotspots = setupHotspots;
