import 'featherlight';
import './block-location.js';
import './block-image-gallery';
import './block-transporter';
import './block-hotspots';
import './block-logos';
import './single-product-type';
import './page-my-page';
import './refrence-slider.js';
import './search.js';
import './hamburger.js';
import './shop';
