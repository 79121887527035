import {showHotspot} from './block-hotspots'


function setupTransporter() {

    var $transporter = $('.nordic-bulk-transporter');
    var $imageDiv = $transporter.find('.image-container');

    var $select = $transporter.find('select');
    $select.off('change').change(function(e) {
        var val = $(this).val();

        showItemWithValue(val);
    });

    var $selection = $transporter.find('.selection');
    $selection.find('input[type="radio"]').off('change').change(function (e) {
        var val = $(this).val();

        showItemWithValue(val);
    });

    $transporter.find('h3 a').off('click').click(function (e) {
        e.preventDefault();

        var $parentDiv = $(this).parents('h3').parent();
        var isActive = $parentDiv.hasClass('active');

        $selection.find('.group').find('> div').removeClass('active');

        if (!isActive) {
            $parentDiv.addClass('active');
        }
    });

    var $activeDiv = $imageDiv.find('> div:first-child');
    if ($activeDiv.length > 0) {
        var $hotspot = $activeDiv.find('.hotspot');
        if ($hotspot.length > 0) {
            showHotspot(null, $hotspot, true);
        }
    }

    function showItemWithValue(val) {
        $imageDiv.find('> div').removeClass('active');

        var $activeDiv = $imageDiv.find('> div.' + val);
        $activeDiv.addClass('active');

        if ($activeDiv.length > 0) {
            var $hotspot = $activeDiv.find('.hotspot');
            if ($hotspot.length > 0) {
                showHotspot(null, $hotspot, true);
            }
        }
    }
}

$(document).ready(function() {
    setupTransporter();
});

window.setupTransporter = setupTransporter;
