const $products = $('.product-type__parts-list');

$products.find('.subtract').click(function (e) {
    let $amountInput = $(this).siblings('.qty');
    let amount = parseInt($amountInput.val()) || 0;

    updateAmount($amountInput, amount - 1)
});

$products.find('.add').click(function (e) {
    e.preventDefault();
    let $amountInput = $(this).siblings('.qty');
    let amount = parseInt($amountInput.val()) || 0;

    updateAmount($amountInput, amount + 1)
});

function updateAmount($input, amount) {
    amount = amount || 0;
    amount = Math.max(amount, 1);

    $input.val('' + amount);
}