const toggle = document.getElementById('search-toggle')
const searchForm = document.querySelector('form.search-form')
let searchOpen = false

// If search form and toggle exists add event listeners
if (toggle != null && searchForm != null) {
    // Toggle mobile menu
    toggle.addEventListener('click', () => {
            console.log('clikk')
            searchForm.classList.toggle('hidden')
            searchOpen = !searchOpen;
        }
    )

    //  Escape closes mobile menu
    document.addEventListener('keydown', (e) => {
        if (searchOpen && e.key === "Escape") {
            searchForm.classList.add('hidden')
            searchOpen = false
        }
    })
}